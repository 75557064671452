<template>
  <v-row>
    <v-col
      v-for="(field, index) of fields"
      :cols="field.colSize || 12"
      :key="index"
    >
      <input-v
        v-model="value[field.key]"
        :label="field"
        :opts="opts"
        :editable="!sending && !field.disabled"
        @blur="field.blur  ? field.blur : () => {}"
        @input="field.input"
        @update:valid="validMap.set(field.key, $event)"
      ></input-v>
    </v-col>
    <action-buttons
      :deleteUser="!isNewUser"
      :resetPassword="!isNewUser"
      :sending="sending"
      :userEmail="user.email"
      :userId="user.id"
      :userName="user.nome"
      @error="$emit('error', $event)"
      @save="doSave()"
      @update:sending="$emit('sending', $event)"
    ></action-buttons>
  </v-row>
</template>

<script>
import { UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    "action-buttons": () => import("./action.buttons.vue"),
    "input-v": () => import("@/components/input-v.vue")
  },
  computed: {
    fields: function () {
      const allFields = [
        {
          key: "email",
          name: "Email",
          colSize: 6,
          input: this.autofillUser,
          type: this.$fieldTypes.TEXT,
          placeholder: 'nome@fornecedor.com.br',
          rules: [{ rule: "required" }, { rule: "email" }],
        },
        {
          key: "usuario",
          name: "Usuário",
          colSize: 6,
          type: this.$fieldTypes.TEXT,
          placeholder: 'Será igual ao e-mail por padrão',
          rules: [{ rule: "required" }],
          blur: () => {
            if (!this.value.usuario) {
              this.fillUser = true;
              this.value.usuario = this.value.email;
            }
          },
          input: () => this.fillUser = false,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          colSize: this.isNewUser ? 6 : 10,
          placeholder: 'Nome e sobrenome',
          rules: [{ rule: "required" }, { rule: "min", params: { size: 3 }}],
        },
        {
          hide: !this.isNewUser,
          key: "senha",
          name: "Senha Provisória",
          colSize: 4,
          type: this.$fieldTypes.TEXT,
          placeholder: 'Novas contas precisam de uma senha inicial',
          rules: [{ rule: "required" }],
        },
        {
          key: "ativo",
          name: "Status",
          colSize: 2,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: true, nome: "Ativo" },
              { id: false, nome: "Inativo" },
            ],
            key: "id",
            name: "nome",
          },
        },
      ];

      return allFields.filter(({ hide }) => !hide);
    },
  },
  created: function () {
    this.updateValue(this.user);
  },
  data: function () {
    const validMap = new Map();
    return {
      fillUser: true,
      validMap,       
      sending: false,
      value: {
        ativo: true,
        email: null,
        nome: null,
        senha: null,
        usuario: null,
      },
    };
  },
  methods: {
    autofillUser: function (nextValue) {
      if (this.fillUser) {
        this.value.usuario = nextValue;
      }
    },
    doSave: function () {
      const fieldErros = [];
      this.validMap.forEach((validation, key) => {
        if (!validation()) {
          fieldErros.push(key);
        }
      });

      if (fieldErros.length > 0) {
        const fieldNames = fieldErros.map((fieldKey) => this.fields.find(({ key }) => key === fieldKey)?.name || fieldKey).join(', ');
        return this.$emit('error', { error: `Campos inválidos: ${fieldNames}.` });
      }

      const { id } = this.user;
      const body = {
        ...this.value,
        tipo_usuario: UserTypeEnum.ADMINISTRADOR,
      };

      if (id && isFinite(id)) {
        body.id = id;
      }

      if (!body.senha) {
        delete body.senha;
      }

      this.$emit('save', body);
    },
    updateValue: function (nextValue = {}) {
      this.value = {
        ativo: nextValue.ativo,
        email: nextValue.email,
        nome: nextValue.nome,
        senha: nextValue.senha,
        usuario: nextValue.usuario,
      };
    },
  },
  props: {
    isNewUser: { type: Boolean, default: false },
    opts: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
  },
  watch: {
    user: {
      deep: true,
      handler: function (nextValue) {
        this.updateValue(nextValue);
      },
    },
  },
}
</script>
